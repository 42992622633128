import React from "react"
import { Box, Text } from "grommet"
import { Layout, SEO, Template, ErrorInfo } from "../components"
import { useApi } from "../hooks"
import { getApiFecher } from "../utils"
const Page = () => {
  const { data, error } = useApi(
    `/templates`,
    getApiFecher({ shouldUseAuth: false })
  )
  return (
    <Layout>
      <SEO title="Home" />
      {error && <ErrorInfo>{error.message}</ErrorInfo>}
      {!error && (
        <Box pad="medium">
          <Text>Choose a template</Text>
          <Box>
            {data &&
              data.data &&
              data.data.items &&
              data.data.items.map(template => (
                <Template key={template.templateId} {...template}></Template>
              ))}
          </Box>
        </Box>
      )}
    </Layout>
  )
}

export default Page
